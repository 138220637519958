// Here you can add other styles

body {

  font-size: 80% !important;

}

.icon-spin {

  animation: icon-spin 2s linear infinite;

}

.noBreak {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@keyframes icon-spin {
 0% {
  transform:rotate(0deg)
 }
 100% {
  transform:rotate(1turn)
 }
}

.cursor-pointer {

  cursor: pointer;

}

.modal-full {

    min-width: 100%;
    max-width: 100%;
    margin: 0;

    overflow: hidden !important;

}

.modal-full .modal-content {
    min-height: calc(100vh - 60px) !important;
    max-height: calc(100vh - 60px) !important;
    overflow: auto;
}

.modal-full .modal-dialog {

  margin: 0;
  min-width: 100%;
  padding: 30px 30px;

  position: relative;

}

.env-warning {

  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  opacity: 0.5;

  height: 55px;
  line-height: 55px;

  text-align: center;

  background: repeating-linear-gradient(
    -45deg,
    #003082,
    #003082 10px,
    #4fa1ff 10px,
    #4fa1ff 20px
  );

  text-transform: uppercase;
  text-stroke: 1px black;
  -webkit-text-stroke: 1px black;

  color: white;

  text-shadow: 0 0 10px black;

  border-bottom: 2px solid white;

  letter-spacing: 0.5px;
  font-weight: bolder;
  font-size: 25px;

  z-index: 1049;

  animation: animateIn ease-out 1s;
  animation-iteration-count: 1;
  transform-origin: 50% 50%;
  animation-delay: 0.5s;
  animation-fill-mode:forwards;

  transform: translate(0, -100px);

}

@keyframes animateIn {

  0% {
    transform:  translate(0, -100px);
    height: 55px;
    line-height: 55px;
    font-size: 25px;
    opacity: 1;
  }

  50% {
    transform:  translate(0, -100px);
    height: 120vh;
    line-height: 120vh;
    font-size: 70px;
    opacity: 1;
  }

  100% {
    transform:  translate(0, 0);
    height: 55px;
    line-height: 55px;
    font-size: 25px;
    opacity: 0.3;
  }

}


/* React Select -- Inline Modifier
* Inline container with margin for better inline spacing between other elements
*/
.react-select--inline {
  display: inline-block;
  margin: 0 0.25em;
}

/* Remove border, outline, box-shadow, and min-height values */
.react-select--inline .react-select__control {
  border: none;
  outline: none;
  box-shadow: none;
  min-height: none;
  cursor: pointer;
}

/* Tighten up spacing */
.react-select--inline .react-select__value-container {
  padding: 0 5px;
}

/* Position value relative (instead of absolute) and remove transform and max-width */
.react-select--inline .react-select__single-value {
  font-weight: bold;
  position: relative;
  transform: none;
  max-width: none;
  color: var(--primary);
}

/* CSS triangle dropdown indicator after the selected value */
.react-select--inline .react-select__single-value::after {
  content: " ";
  position: relative;
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  border-color: #000000 transparent transparent transparent;

  margin-left: 0.25em;
  top: -0.125em;
}


/* Widgets */
.widget {

  min-height: 150px;
  max-height: 150px;
  overflow: scroll;

  transition: max-height 500ms ease-out;

  &:hover {

    max-height: 500px !important;

  }

  &.widget-large {

      min-height: 250px;
      max-height: 250px;

  }

}

/* Datepciker */
.react-datepicker-wrapper,
.react-datepicker__input-container {

  display: inline !important;

}
